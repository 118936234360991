export default {
  t1: "gitbook",
  lianqianbao:"Connect Wallet",
  t2: "FERC20: A more equitable ERC20 programme",
  t2_1: "Practicing Satoshi Nakamoto's Spirit and Bitcoin Decentralisation",
  t3: "Public Offering",
  t4: "Subscription Address",
  t5: "Please protect your payment account. The computing power, rights, and dividends generated by purchasing nodes will be automatically distributed to your address",
  t6: "Programme Profile",
  t7: "Cast Swap is committed to solving FERC20's illiquidity problem by increasing the liquidity pool for LP transactions and enabling real-time trading. Additionally, in order to reduce the complexity of trading FERC tokens and enable users to buy, sell and add to the liquidity pool more easily, Cast Swap charts the first use of the ERC network using the LP model, allowing for a more diversified use of ETH assets.",
  t8: "To provide online and offline payment, exchange, and other services for global blockchain and digital currency users and merchants",
  t9: "Blockchain digital currencies will be as convenient to use as PayPal and Alipay. Issuing CAST coins as the sole token for transaction fees generated during the circulation and exchange period",
  t10: "millions coins",
  t11: "Total circulation of CAST coins",
  t12: "IOD Pre-Sale",
  t13: "Ecological Application",
  t13_1:"Community Building",
  t13_2:"Reserve fund",
  t13_3:"Airdrop",
  t14: "CastSwap is designed to address the illiquidity of FERC20 by increasing the liquidity pool for LP trades and enabling real-time trading.",

  t15: "Active MM Modelling",
  t16: "Automated Market Maker (AMM) model: CastSwap uses the AMM model to simplify the trading process and reduce reliance on the order book",
  t17: "Liquidity Terms",
  t18: "Liquidity provision and incentives: Users can provide liquidity to the platform and receive incentives to ensure sufficient liquidity for a better trading experience",
  t19: "User-friendly interface",
  t191: "User-friendly interface: CastSwap provides a clean, intuitive interface that allows users to easily connect their wallets and interact with the platform",

   p3: "Balanced Internal and External Development",
  p4: "We have a leading team of internal control experts to ensure strict compliance with the high standards set by the Sarbanes-Oxley Act of the United States in terms of corporate governance, internal control, financial auditing, and more. We engage one of the Big Four for financial auditing and undergo security and compliance audits by top-tier partner banks worldwide.",
  p5: "August 16th UTC 13:00 - August 19th UTC 13:00",
  p6: "Launching the TABN program",
  p7: "$FERC New Narrative, Another New Change in the Coinosphere ",
  p8: "Coin's new narrative IFO fair launch revolution is about to detonate!",
  p9: "the affirmative action movement of the awakening of the leeks, the engine of the next bull market.",
  p10: "Account Security",

  t20: "Total value (T-value) of all market makers directly invited",

  t21: "Pre-sale Rules",
  t22: "National Coverage",
  t23: "Ecosystem Collaboration Partners",
  t24: "Compliant Financial Service Countries or Regions",
  t25: "Seamless Exchange of Currencies",
  t26: "2.28 million",
  t26_1: "7.5 million",
  t27: "Unit price 0.04$",
  t28: "Prompt settlement",
  t29: "Total over-subscription/$7.5 million",
  t30: "Issued after the end of the super curtain",

  t31: "Planning",
  t32: "Apply for Multiple Payment Accounts",
  t33: "You can apply for multiple accounts in the same currency for different business purposes.",
  t34: "eBay Integration",
  t35: "Complete eBay integration, seamlessly connect from store setup to registered payments, faster deposit, more effective fund management.",
  t36: "EasyPay",
  t37: "Develop the EasyPay series",
  t38: "No recharge fees, no transaction fees, and no withdrawal fees.",
  t39: "Enhanced Mobile Features",
  t40: "The app allows you to download and directly share bills, making bill reconciliation easier.",
  t41: "App Security Settings: Including password modification, security questions, 3D security, etc.",
  t42: "Achievements",
  t43: "CAST plays a vital role in the ecosystem as the backbone of the platform. CAST tokens provide holders with multiple use cases and benefits that including:",
  t44: "Governance: CAST Token holders have voting rights on proposals related to platform development, improvements and future initiatives, giving them direct influence over the direction and growth of the CastSwap ecosystem.",
  t45: "Liquidity Incentive: Users who contribute liquidity to the CastSwap pool and participate in the AMM system receive CAST tokens as an incentive to encourage more users to contribute liquidity and ensure a healthy trading environment.",
  t46: "Pledge: CAST token holders can pledge their tokens for additional rewards, such as a share of platform transaction fees or other incentives, thus providing passive income opportunities.",
  t47: "Platform Utility: CAST tokens can be used in the CastSwap ecosystem for a variety of purposes, such as paying transaction fees, accessing advanced features or participating in exclusive token sales and events.",
  t48: "Get passive income with cryptocurrencies, CastSwap makes it easy to use cryptocurrencies!",
  t49: "Global Payment",
  t50: "Global Cross-border E-commerce payment solutions",
  t51: "Physical & Virtual Cards",
  t52: "Multi-currency Accounts",
  t53: "Mobile ATMs",
  t54: "We Go Beyond Expectations",
  t55: "We are dedicated to maintaining consistently high standards of user experience and services, constantly motivating ourselves to meet customer demands, address customer needs, and alleviate customer concerns.",
  t56: "Compliance & Risk Control Services",
  t57: "POS Machine",
  t58: "Accessible Conversion",
  t59: "Offline Payment Collection",
  t60: "We Build Trust Beyond Boundaries",

  t61: "We value and uphold honesty and integrity, maintaining open communication with our internal team and clients. We believe it is the entrepreneurial spirit of our clients that makes us thrive and excel.",
  t62: "Our Values",
  t63: "We Go Beyond Assumptions",
  t64: "We believe it is the entrepreneurial spirit of our clients that makes us thrive and excel. As a multinational company, we care for and respect one another, possessing a strong awareness of customer service and responsibility.",
  t65: "We Go Beyond Care",
  t66: "We aim to help our clients grow and succeed, discovering and fulfilling their business needs together, continuously exceeding expectations.",
  t67: "We Go Beyond Borders",
  t68: "We believe that geographical and cultural differences should not deprive anyone of equal opportunities for success. It is diversity that enriches and makes the world more wonderful every day.",
  t69: "Service Provider Partnership: Linking CAST, Connecting Global Opportunities",
  t70: "As a free direct payment service provider, you can integrate with e-commerce systems and enjoy exclusive partnership benefits. Once seamlessly integrated via API, you can easily deduct payments from users' CAST balances, providing a simple and efficient collection experience. Built into the platform, you can enjoy a low-cost and worry-free collection experience.",
  t71: "Advertising",
  t72: "Localization & Translation",
  t73: "Verification Services",
  t74: "Logistics",
  t75: "Software",
};
