export default {
  t1: "白皮书",
  lianqianbao:"",
  t2: "世界的每一個機會你都有機會",
  t3: "公開募集",
  t4: "認購地址",
  t5: "請保護好您的付款帳戶，購買節點後項目產生的算力，權益，以及分紅都將自動發放到您的地址",
  t6: "公司簡介",
  t7: "是香港區區塊鏈交易所的生態服務，已與全球各大交易所合作 研發生產線上電商數字貨幣支付系統，線下櫃員機，pos機，商家收款碼，用戶下單核銷系統等。",
  t8: "為全球區塊鏈數字貨幣用戶以及商家提供線上線下收付款，兌換等服務。",
  t9: "區塊鏈數字貨幣將和PayPal以及支付寶一樣便捷使用。發行CAST幣作為流通兌換期間產生的手續費的唯一通證。",
  t10: "萬枚",
  t11: "CAST流通幣發行總量",
  t12: "IOD Pre-Sale",
  t13: "社区应用",
  t13_1:"社区建设",
  t13_2:"CEX储备基金",
  t13_3:"空投",
  t14: "CastSwap 旨在通过增加 LP 交易的流动性池并实现实时交易来解决 FERC20 流动性不足的问题。",

  t15: "主动式MM模型",
  t16: "自动做市商（AMM）模型：CastSwap采用AMM模型，简化了交易流程，减少了对订单簿的依赖",
  t17: "流动性条款",
  t18: "流动性提供和激励：用户可以向平台提供流动性并获得激励，以确保充足的流动性以获得更好的交易体验",
  t19: "方便使用的界面",
  t20: "用户友好的界面：CastSwap 提供了一个干净、直观的界面，允许用户轻松连接钱包并与平台交互",

   p3: "內外兼修",
  p4: "擁有行業領先的內控專家團隊。確保公司嚴格遵守美國《薩奧法》高標準規定之公司治理、內部控制、財務審計等各方面；聘請了四大作為財務審計，接受全球一流的頭部合作銀行對我們的業務安全和合規審計",
  p5: "7月17 日UTC 13:00 - 7月19 日UTC 13:00",
  p6: "開展TABN計劃",
  p7: "$FERC新叙事,币圈又一场新变革",
  p8: "币圈新叙事IFO公平发射革命即将引爆！",
  p9: "韭菜觉醒的平权运动，下轮牛市的发动机，",
  p10: "賬戶安全",

  t20: "直接邀請的所有做市商T值之和",

  t21: "预售规则",
  t22: "國家覆蓋",
  t23: "生態圈合作夥伴",
  t24: "合規金融服務國家或區域",
  t25: "幣種無障礙對換",
  t26: "228万",
  t26_1: "750万",
  t27: "单价0.04$",
  t28: "即时到账",
  t29: "超募总金额/750万",
  t30: "超幕结束后发放",

  t31: "規劃",
  t32: "自助申請多收款賬號",
  t33: "可以自助申請同一幣種下更多的賬號，用於不同商業目的",
  t34: "eBay對接",
  t35: "完成eBay對接，從開店到註冊收款無縫對接，到賬更迅速，資金管理更有效",
  t36: "隨心付",
  t37: "研發隨心付系列",
  t38: "不用充值費，沒有交易手續費，省去提現費",
  t39: "移動端功能不斷加強",
  t40: "App端可以下載和直接分享帳單，提供對帳單更簡單",
  t41: "App安全設置：包括修改密碼，安全問題，3D安全等",
  t42: "實現",
  t43: "CAST作为平台的支柱，在生态系统中发挥着至关重要的作用。 CAST 代币为持有者提供多种用例和好处，包括：",
  t44: "治理：CAST 代币持有者对与平台开发、改进和未来举措相关的提案拥有投票权，这使他们对 CastSwap 生态系统的方向和发展具有直接影响力。",
  t45: "流动性奖励：向CastSwap池提供流动性并参与AMM系统的用户可以获得CAST代币作为激励，鼓励更多用户贡献流动性，保证健康的交易环境。",
  t46: "质押：CAST 代币持有者可以质押其代币以获得额外奖励，例如分享平台交易费用或其他激励措施，从而提供被动收入机会。",
  t47: "平台实用性：CAST 代币可在 CastSwap 生态系统中用于各种目的，例如支付交易费用、访问高级功能或参与独家代币销售和活动。",
  t48: "用加密货币来获取被动收入，CastSwap 让您轻松使用加密货币",
  t49: "環球支付",
  t50: "全球跨境電商收付方案",
  t51: "實體&虛擬卡",
  t52: "多幣種賬戶",
  t53: "移動櫃員機",
  t54: "我們超越期望",
  t55: "我們致力於維護一貫高水準的用戶體驗與服務，並不斷激勵自己想客戶之所求，急客戶之所需，排客戶之所憂。",
  t56: "合規&風控服務",
  t57: "POS機",
  t58: "無障礙兌換",
  t59: "線下收款",
  t60: "我們超越信任",

  t61: "我們重視並奉行誠實正直的品質，持續與內部團隊和客戶保持溝通。我們認為，正是客戶的創業精神成就和激勵了我們。",
  t62: "我們的價值觀",
  t63: "我們超越臆斷",
  t64: "我們認為，正是客戶的創業精神成就和激勵了我們。作為一個跨國公司，我們互相關懷與尊重，擁有強烈的客戶服務意識與責任感。",
  t65: "我們超越關懷",
  t66: "我們以幫助客戶成長與發展為目標，與客戶一起發現和滿足業務需求，並不斷超越期望。",
  t67: "我們超越疆界",
  t68: "我們相信，地域與文化的差異無法剝奪每個人獲得成功的平等機會，也正是得益於多樣性，世界才日益豐富精彩。",
  t69: "服務商合作：連接CAST，連接全球商機",
  t70: "免費直付服務商，對接電商業務系統，享受獨家合作福利。API無縫對接後，即可從用戶的CAST余額中輕鬆扣款，簡單快捷。內置於平臺中，暢享低成本無憂收款體驗。",
  t71: "廣告",
  t72: "本地化&翻譯",
  t73: "檢測服務",
  t74: "物流",
  t75: "軟件",
};
