import {
	createI18n
} from 'vue-i18n'
import zhHk from './zh-hk.js'
import en from './en.js'

// 创建 i18n
const i18n = createI18n({
	legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
	globalInjection: true, // 全局模式，可以直接使用 $t
	locale: localStorage.getItem('lang') || 'en',
	messages: {
		zhHk,
		en
	}
})

export default i18n
