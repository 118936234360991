

import {
	createApp
} from "vue";
import i18n from './locales/index.js'

import store from "./store";
import App from "./App.vue";
import router from "./router";


import "bootstrap/dist/css/bootstrap.min.css";

import "./assets/less/main.less";

import { SnackbarService } from "vue3-snackbar";
import "vue3-snackbar/styles";

// import 'animate.css/animate.min.css' //引入
// import 'animate.css' //这是官方的引入，但是会用不了

import animated from 'animate.css';



const app = createApp(App)

app.use(SnackbarService);
app.use(store);
app.use(router);
app.use(animated);

// ant 开始
// import {
// 	Select,
// 	Button,
// 	message
// } from 'ant-design-vue';

// import 'ant-design-vue/dist/antd.css';
// 或者 ant-design-vue/lib/button/style/css 加载 css 文件


// app.use(Select);
// import 'ant-design-vue/lib/select/style/css';

// app.use(Button);
// import 'ant-design-vue/lib/button/style/css';


// import 'ant-design-vue/lib/collapse/style/css';
// app.use(Collapse);


// app.use(message);
// import 'ant-design-vue/lib/message/style/css';


// ant 结束


// import Varlet from '@varlet/ui'
// import '@varlet/ui/es/style'
// app.use(Varlet);

// import lineClamp from 'vue-line-clamp'
// app.use(lineClamp, {});

// import VueEllipsis from 'vue-ellipsis'
// app.use(VueEllipsis);

// import TextClamp from 'vue3-text-clamp';
// app.use(TextClamp);

// 指令开始


// 指令结束

app.use(i18n);

app.mount("#app");

// import "bootstrap/dist/js/bootstrap.js";
