import {
	createRouter,
	createWebHistory
} from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Layout from "@/layout/layout.vue";
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'

const routes = [
	// {
	//   path: '/',
	//   // redirect: '/',
	// component: () => import('@/views/home/home.vue')
	// },
	{
		path: "/",
		name: "Layout",
		// redirect: '/home',
		component: Layout,
		children: [
			{
			path: "/",
			name: "Home",
			component: () => import("@/views/home/home.vue")
		}, ]
	},

];





// const routes = [{
// 		path: "/",
// 		name: "home",
// 		component: Layout, // 共同布局
// 		children: [
// 			// {
// 			// 	path: "/",
// 			// 	name: "home",
// 			// 	component: import("@/views/home/home.vue"),
// 			// },
// 			// {
// 			//   path: "/about",
// 			//   component: import("@/views/about/about.vue"),
// 			// },
// 		],
// 	},
// 	{
// 	  path: "/",
// 		name: "home",
// 	  component: import("@/views/home/home.vue"),
// 	},
// 	{
// 		path: "/about",
// 		name: "about",
// 		component: import("@/views/about/about.vue"),
// 	},
// ];

const router = createRouter({

	history: createWebHistory(),
	// history: createWebHistory(process.env.BASE_URL),
	routes,
});


//隐藏环形进度条
NProgress.configure({
	showSpinner: false
})

router.beforeEach(async (to, from, next) => {
	// start progress bar
	NProgress.start()
	next()
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})

export default router;